<template>
    <div class="table-card">
        <van-row v-for="(item,index) in data" v-bind:key="index" >
            <van-col class="table-col-head" span="13"
                     v-html="item.text"></van-col>
          <van-col span="11"
                   v-html="item.val" class="table-col"></van-col>
        </van-row>
<!--        <van-row :class="'row-one'">-->
<!--            <van-col v-for="(item,index) in data.tbody" :span="data.span[index]" class="an-ellipsis table-col"-->
<!--                   v-bind:key="index" v-html="item">-->
<!--            </van-col>-->
<!--        </van-row>-->
    </div>
</template>

<script>
    import Vue from 'vue'
    import {Row, Col, Cell} from 'vant'

    Vue.use(Row).use(Col).use(Cell)
    export default {
        name: "TableCard",
        components: {},
        props: {
            data: {type: Object}
        },
    }
</script>

<style scoped>
    .table-card {
        margin: 0 10px;
        padding: 0 5px;
        padding-bottom: 10px;
        background: #FFFFFF;
        border-radius: 7px;
    }

    .table-card .table-col-head {
        font-size: 14px;
        color: white;
        background: #7DC8FF;
        padding: 10px;
        text-align: left;
    }

    .table-card .table-col {
        text-align: center;
        padding: 12px 0;
        font-size: 12px;
        background: #EBF8FD;
    }

</style>
