<template>
  <div class="digital-record">
    <track-card :data="data.data"></track-card>
    <div v-if="this.data" class="synopsis-title">
      {{ this.data.data.synopsisTitle }}
    </div>
    <div v-if="this.data" class="synopsis-title1">
      {{ this.data.data.synopsisTitle1 }}
    </div>

    <van-grid :border="false">
      <van-grid-item
          class="time"
          v-for="(item,index)  in data.time"
          :key="index"
          :text=item.text
      />
    </van-grid>

<!--    <div v-if="this.data.info">-->
      <list-card :data="data.list"></list-card>
      <list-card :data="data.list1"></list-card>
<!--    </div>-->

    <van-notice-bar>
      （复试分享考评标准：优秀90-100；良好80-89；一般70-79；其他70以下；）
    </van-notice-bar>

    <div v-if="this.data" class="synopsis-title1">
      {{ this.data.data.synopsisTitle2 }}
    </div>
    <div v-if="this.data" class="synopsis-title11">
      {{ this.data.data.synopsisTitle21 }}
    </div>

    <div v-if="this.data">
      <table-card :data="data.data.table"></table-card>
    </div>

    <div v-if="this.data" class="synopsis-title11">
      {{ this.data.data.synopsisTitle22 }}
    </div>

    <div v-if="this.data">
      <table-card v-if="this.data" :data="data.data.table1"></table-card>
    </div>

    <div v-if="this.data" class="synopsis-title11">
      {{ this.data.data.synopsisTitle23 }} {{this.data.data.sufferDetailText1}}
    </div>

    <div v-if="this.data" class="synopsis-title11">
      {{ this.data.data.synopsisTitle24 }}  {{this.data.data.departmentDetailText1}}
    </div>

    <van-notice-bar>
      （复试分享考评标准：优秀90-100；良好80-89；一般70-79；其他70以下；）
    </van-notice-bar>

    <div v-if="this.data" class="synopsis-title11">
      {{ this.data.data.synopsisTitle25 }}
    </div>

    <div v-if="this.data">
      <list-card :data="data.list2"></list-card>
    </div>


    <div v-if="this.data" class="synopsis-title11">
      {{ this.data.data.synopsisTitle26 }}
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {Row, Col, Grid, GridItem} from 'vant'
import TrackCard from "../components/DigitalCell/TrackCard";
import ListCard from "../components/DigitalCell/ListCard";
import TableCard from "../components/DigitalCell/TableCard";
import {getDigitalRecord} from '@/api/digital';

Vue.use(Row).use(Col).use(Grid).use(GridItem)

export default {
  name: "DigitalRecord",
  components: {TrackCard, ListCard, TableCard},
  data() {
    return {
      data: {
        data: {
          info: [],
          synopsisTitle: '',
          synopsisTitle1: '',
          synopsisTitle2: '',
          synopsisTitle21: '',
          synopsisTitle22: '',
          synopsisTitle23: '',
          synopsisTitle24: '',
          synopsisTitle25: '',
          synopsisTitle26: '',
          time: [],
          table: [],
          table1: [],
          table2: [],
          table3: [],
        },
        list: {
          column: 4,
          span: [9, 5, 5, 5],
          thead: [],
          tbody: []
        },
        list1: {
          column: 3,
          span: [5, 14, 5],
          thead: [],
          tbody: []
        },
        list2: {
          column: 5,
          span: [5, 5, 5, 5, 4],
          thead: [],
          tbody: []
        },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      getDigitalRecord({userNo: this.$route.params.id}).then(res => {
        console.log(res)
        if (res.length > 0) {
          this.data.data = res[0]
          res.forEach((item) => {
            this.data.list.thead = []
            this.data.list.tbody = []
            this.data.list1.thead = []
            this.data.list1.tbody = []
            this.data.list2.thead = []
            this.data.list2.tbody = []
            this.data.list.thead.push(item.rotationGroupText, item.rotationStudyText, item.flowCheckText, item.deductCauseText)
            this.data.list.tbody.push(item.rotationGroupValue, item.rotationStudyValue, item.flowCheckValue, item.deductCauseValue)
            this.data.list1.thead.push(item.specialWorkText, item.workExamineText, item.examinePersText)
            this.data.list1.tbody.push(item.specialWorkValue, item.workExamineValue, item.examinePersValue)
            this.data.list2.thead.push(item.retestDetailText1, item.retestDetailText2, item.retestDetailText3, item.retestDetailText4, item.retestDetailText5)
            this.data.list2.tbody.push(item.retestDetailValue1, item.retestDetailValue2, item.retestDetailValue3, item.retestDetailValue4, item.retestDetailValue5)
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
  }
}
</script>

<style scoped>
.digital-record {
  /*margin: 10px;*/
  background-color: #FFFFFF;
}

.digital-record .synopsis-title {
  font-size: 18px;
  text-align: left;
  margin: 5px;
}

.digital-record .synopsis-title1 {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  margin: 5px;
}

.digital-record .synopsis-title11 {
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  margin: 10px 10px 10px 15px;
}

.digital-record .time {
  font-weight: bold;
}
</style>