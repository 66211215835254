<template>
    <div class="track-card">
        <div class="title-div">
            员工档案
        </div>

        <van-row class="info-items">
            <van-grid :column-num="2">
                <van-grid-item v-for="(item,index) in data.info" :key="index">
                    <van-row :style ="{color: index%2===0 ? '#646566' : '#557DC3', textAlign: 'left', fontSize: '14px'}">
                        {{item.text}}
                    </van-row>
                </van-grid-item>
            </van-grid>
        </van-row>
    </div>
</template>

<script>
    import Vue from 'vue'
    import {Row, Col, Grid, GridItem} from 'vant'

    Vue.use(Row).use(Col).use(Grid).use(GridItem)

    export default {
        name: "TrackCard",
        components: {},
        props: {
            data: {type: Object}
        },
        methods: {}
    }
</script>

<style scoped>

    .title-div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 22px;
        padding-top: 10px;
    }

    .track-card {
        background: white;
        margin: 10px 10px;
        border-radius: 7px;
        box-shadow: rgba(85, 126, 198, 0.37) 0 0 6px 0;
        padding-bottom: 5px;
    }

    .track-card .info {
        text-align: left;
        font-size: 14px;
        color:  #646566 !important;
    }

    .track-card .info-items {
        /*margin-top: 10px;*/
    }
</style>
<style>
    .track-card .name-row div.van-grid-item__content {
        padding: 10px 2px;
        font-size: 10px;
    }
</style>
